<template>
    <div>
        <navheader></navheader>
        <div class="submitBox">
            <div class="submitBox-header">
                <div class="header-title">
                    <p>{{submitData.name}}</p>
                </div>
                <div class="header-middle-title">
                    <div class="title-icon">
                        <img src="../assets/img/resultsIcon.png" alt="">
                    </div>
                    <p>下次再接再厉哦~</p>
                </div>
                <div class="middle-circle">
                    <p class="circle-title">{{submitData.totalScore}}</p>
                    <p class="circle-sub">分</p>
                </div>
                <div class="middle-color-title">
                    <p>正确率：{{submitData.accuracy}}</p>
                </div>
                <div class="middle-detail">
                    <p><span style="display: none;">此试卷最高平均分：</span><span style="display: none;">{{submitData.averageScore}}分</span><span style="display: none;">排名：第 {{submitData.rank}} 名</span><span style="display: none;">击败考生：{{submitData.defeat}}人</span><span>答题用时：{{submitData.useTime}}分钟</span><span>答题量：{{submitData.answerNum}}道</span><span>正题：{{submitData.trueCount}}道</span><span>错题：{{submitData.errorCount}}道</span></p>
                </div>
                <div class="bottom-button" @click="toShow()">
                    <p>试题解析</p>
                </div>
            </div>
            <div class="submitContent-box">
                <div class="submitContent">
                    <div class="submitContent-table">
                        <ul class="top-ul">
                            <li v-for="(item,index) in topUl" :key="index">{{item}}</li>
                        </ul>
                        <ul class="bottom-ul">
                            <li>{{submitData.total}}</li>
                            <li>{{submitData.answerNum}}</li>
                            <li>{{submitData.trueCount}}</li>
                            <li>{{submitData.errorCount}}</li>
                            <li>{{submitData.accuracy}}</li>
                            <li>
                                <div class="ul-button" @click="toShow()">
                                    <p>查看解析</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <navfooter></navfooter>
    </div>
</template>

<script>
import navheader from '../components/NavHeader'
import navfooter from '../components/NavFooter'
export default {
    data() {
        return {
            resultId: this.$route.params.resultId,
            topUl: ['总题数','做题数','正题数','错题数','正确率','试题解析'], //内容页数据展示
            submitData: {},
            submitResultId: ''
        }
    },
    mounted() {
        this.getSubmitData();
    },
    components: {
        navheader,
        navfooter,
    },
    metaInfo: {
        title: '考试结果-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    methods: {
        getSubmitData() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/answer/returnResults.html',
                data: {
                    resultId: this.resultId
                },
            }).then((res) => {
                this.submitData = res.data.result.result;
                this.submitResultId = res.data.result.result.resultId;
            })
        },
        toShow() {
            this.$router.push({
                name: 'showquestion',
                params:{resultId:this.submitResultId}
            })
        }
    }
}
</script>

<style lang="scss">
    .submitBox {
        width: 100%;
        min-width: 1200px;
        .submitBox-header {
            padding-top: 60px;
            box-sizing: border-box;
            width: 100%;
            min-width: 1200px;
            height: 532px;
            background: linear-gradient(90deg, rgba(255, 90, 116, 0.97), rgba(255, 90, 116, 0.97), rgba(102, 105, 222, 0.97), rgba(102, 105, 222, 0.97));
            opacity: 0.8;
            .header-title {
                margin-bottom: 23px;
                text-align: center;
                font-size: 30px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }
            .header-middle-title {
                display: flex;
                align-items: flex-end;
                margin: 0 auto 25px;
                width: 223px;
                .title-icon {
                    margin-right: 6px;
                    width: 30px;
                    height: 30px;
                }
                p {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
            .middle-circle {
                padding-top: 19px;
                box-sizing: border-box;
                margin: 0 auto;
                width: 155px;
                height: 155px;
                border: 4px solid #FFFFFF;
                border-radius: 50%;
                text-align: center;
                .circle-title {
                    font-size: 50px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                }
                .circle-sub {
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
            .middle-color-title {
                margin-top: 14px;
                margin-bottom: 30px;
                text-align: center;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFEA00;
            }
            .middle-detail {
                margin-bottom: 40px;
                text-align: center;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                span {
                    margin-right: 95px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
            .bottom-button {
                margin: 0 auto;
                width: 200px;
                height: 50px;
                background: #FF5A74;
                border-radius: 25px;
                text-align: center;
                line-height: 50px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                cursor: pointer;
            }
        }
        .submitContent-box {
            width: 100%;
            min-width: 1200px;
            background: #EEEEEE;
            padding-top: 30px;
            padding-bottom: 30px;
            box-sizing: border-box;
            .submitContent {
                padding-top: 40px;
                margin: 0 auto;
                width: 1200px;
                height: 231px;
                background: #FFFFFF;
                .submitContent-table {
                    margin: 0 auto;
                    width: 979px;
                    height: 121px;
                    border: 1px solid #EEEEEE;
                    .top-ul {
                        display: flex;
                        background: #f9f9f9;
                        li {
                            width: 161px;
                            height: 49px;
                            border: 1px solid #EEEEEE;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 49px;
                            text-align: center;
                        }
                    }
                    .bottom-ul {
                        display: flex;
                        li {
                            width: 161px;
                            height: 69px;
                            border: 1px solid #EEEEEE;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 69px;
                            text-align: center;
                            .ul-button {
                                margin: 19px auto;
                                width: 80px;
                                height: 30px;
                                border: 1px solid #FA4B66;
                                border-radius: 4px;
                                font-size: 14px;
                                line-height: 30px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #FF5A74;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
</style>